<template>
  <div>
    <el-table :data="smplForm.smpl_mtrl_list" border @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="48" align="center"></el-table-column>
      <el-table-column label="序号" width="60" align="center">
        <template v-slot="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="物料编号" show-overflow-tooltip width="150px">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'smpl_mtrl_list.' + scope.$index + '.mtrl_no'" :rules="[{ required: true }]">
            <el-tooltip class="item" effect="light" :content="smplForm.smpl_mtrl_list[scope.$index].mtrl_no" placement="top">
              <el-input
                :disabled="true"
                v-model="smplForm.smpl_mtrl_list[scope.$index].mtrl_no"
                maxlength="10"
                show-word-limit
                placeholder="暂无物料编号"
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="物料名称" show-overflow-tooltip>
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'smpl_mtrl_list.' + scope.$index + '.mtrl_name'" :rules="[{ required: true }]">
            <el-tooltip class="item" effect="light" :content="smplForm.smpl_mtrl_list[scope.$index].mtrl_name" placement="top">
              <el-input
                :disabled="true"
                v-model="smplForm.smpl_mtrl_list[scope.$index].mtrl_name"
                maxlength="30"
                show-word-limit
                placeholder="暂无物料名称"
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="物料类型" show-overflow-tooltip>
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'smpl_mtrl_list.' + scope.$index + '.mtrl_type'" :rules="[{ required: true }]">
            <el-input
              :disabled="true"
              v-if="smplForm.smpl_mtrl_list[scope.$index].mtrl_type === 0"
              v-model="mtrlType1"
              show-word-limit
              placeholder="暂无物料名称"
            ></el-input>
            <el-input
              :disabled="true"
              v-else-if="smplForm.smpl_mtrl_list[scope.$index].mtrl_type === 1"
              v-model="mtrlType2"
              show-word-limit
              placeholder="暂无物料名称"
            ></el-input>
            <el-input
              :disabled="true"
              v-else-if="smplForm.smpl_mtrl_list[scope.$index].mtrl_type === 2"
              v-model="mtrlType3"
              show-word-limit
              placeholder="暂无物料名称"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="规格" show-word-limit show-overflow-tooltip>
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'smpl_mtrl_list.' + scope.$index + '.mtrl_spec'">
            <el-tooltip class="item" effect="light" :content="smplForm.smpl_mtrl_list[scope.$index].mtrl_spec" placement="top">
              <el-input
                :disabled="true"
                maxlength="50"
                v-model="smplForm.smpl_mtrl_list[scope.$index].mtrl_spec"
                placeholder="暂无规格"
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="潘通色号" show-overflow-tooltip width="80px">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'smpl_mtrl_list.' + scope.$index + '.mtrl_color'">
            <el-tooltip class="item" effect="light" :content="smplForm.smpl_mtrl_list[scope.$index].mtrl_color" placement="top">
              <el-input
                :disabled="true"
                v-model="smplForm.smpl_mtrl_list[scope.$index].mtrl_color"
                maxlength="20"
                show-word-limit
                placeholder="暂无颜色"
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="克重" show-overflow-tooltip width="60px">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'smpl_mtrl_list.' + scope.$index + '.mtrl_weight'">
            <el-tooltip class="item" effect="light" :content="smplForm.smpl_mtrl_list[scope.$index].mtrl_weight + ''" placement="top">
              <el-input
                :disabled="true"
                show-word-limit
                maxlength="8"
                @blur="
                  smplForm.smpl_mtrl_list[scope.$index].mtrl_weight = helper.retain(smplForm.smpl_mtrl_list[scope.$index].mtrl_weight, 2)
                "
                v-model="smplForm.smpl_mtrl_list[scope.$index].mtrl_weight"
                placeholder="暂无克重"
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="门幅" show-overflow-tooltip width="60px">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'smpl_mtrl_list.' + scope.$index + '.mtrl_width'">
            <el-tooltip class="item" effect="light" :content="smplForm.smpl_mtrl_list[scope.$index].mtrl_width + ''" placement="top">
              <el-input
                :disabled="true"
                show-word-limit
                maxlength="6"
                @blur="
                  smplForm.smpl_mtrl_list[scope.$index].mtrl_width = helper.retain(smplForm.smpl_mtrl_list[scope.$index].mtrl_width, 2)
                "
                v-model="smplForm.smpl_mtrl_list[scope.$index].mtrl_width"
                placeholder="暂无门幅"
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="毛高(毫米)" show-overflow-tooltip>
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'smpl_mtrl_list.' + scope.$index + '.mtrl_thick'">
            <el-tooltip class="item" effect="light" :content="smplForm.smpl_mtrl_list[scope.$index].mtrl_thick + ''" placement="top">
              <el-input
                :disabled="true"
                show-word-limit
                maxlength="6"
                v-model="smplForm.smpl_mtrl_list[scope.$index].mtrl_thick"
                placeholder="暂无毛高"
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>

      <el-table-column label="库位" show-overflow-tooltip width="60px">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'smpl_mtrl_list.' + scope.$index + '.qrcd_bin_list'">
            <el-tooltip
              class="item"
              effect="light"
              :content="smplForm.smpl_mtrl_list[scope.$index].qrcd_bin_list ? smplForm.smpl_mtrl_list[scope.$index].qrcd_bin_list + '' : ''"
              placement="top"
            >
              <el-input
                :disabled="true"
                show-word-limit
                maxlength="6"
                v-model="smplForm.smpl_mtrl_list[scope.$index].qrcd_bin_list"
                placeholder="暂无库位"
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="库存数" show-overflow-tooltip>
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'smpl_mtrl_list.' + scope.$index + '.mstk_num'">
            <el-tooltip
              class="item"
              effect="light"
              :content="smplForm.smpl_mtrl_list[scope.$index].mstk_num ? smplForm.smpl_mtrl_list[scope.$index].mstk_num + '' : ''"
              placement="top"
            >
              <el-input
                :disabled="true"
                show-word-limit
                maxlength="6"
                v-model="smplForm.smpl_mtrl_list[scope.$index].mstk_num"
                placeholder="暂无库存数"
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="200px">
        <template slot="header">
          <span>剪料长宽</span>
        </template>
        <template v-slot="scope">
          <div v-if="scope.row.mtrl_type !== 1">
            <span v-if="scope.row.smplMtrlLenWidList">
              <span v-if="clearFlag">
                <el-tag
                  class="elTagItem"
                  v-for="(item, index) in scope.row.smplMtrlLenWidList"
                  :key="index"
                  :closable="item.closableFlag && !isShow"
                  @close="handleClose(scope.$index, index)"
                  :disabled="isShow"
                  >{{ item.label }}</el-tag
                >
              </span>
            </span>
            <span v-if="scope.row.mtrl_type == '0'">
              <el-button plian class="el-icon-plus vg_pointer circle" plain @click="addTag(scope.$index, index)"></el-button>
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip align="center">
        <template slot="header">
          <span>状态</span>
        </template>
        <template v-slot="scope">
          <div v-if="smplForm.smpl_mtrl_list[scope.$index].mstk_num">
            <el-tag v-if="!smplForm.smpl_mtrl_list[scope.$index].smpl_mtrl_status">未出库</el-tag>
            <div v-else>
              <el-tag v-if="scope.row.smpl_mtrl_status === 0">未出库</el-tag>
              <el-tag v-else type="success">已出库{{ scope.row.smpl_mtrl_status }}次</el-tag>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="单位" show-overflow-tooltip width="50px">
        <template v-slot="scope">
          <el-form-item label-width="1000" :prop="'smpl_mtrl_list.' + scope.$index + '.mtrl_unit'" :rules="[{ required: true }]">
            <el-tooltip
              class="item"
              effect="light"
              :content="smplForm.smpl_mtrl_list[scope.$index].mtrl_unit ? smplForm.smpl_mtrl_list[scope.$index].mtrl_unit + '' : ''"
              placement="top-start"
            >
              <el-input
                :disabled="true"
                show-word-limit
                maxlength="9"
                v-model="smplForm.smpl_mtrl_list[scope.$index].mtrl_unit"
                placeholder="暂无单位"
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="用量">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'smpl_mtrl_list.' + scope.$index + '.smpl_mtrl_consume'">
            <el-tooltip
              class="item"
              effect="light"
              :content="
                smplForm.smpl_mtrl_list[scope.$index].smpl_mtrl_consume ? smplForm.smpl_mtrl_list[scope.$index].smpl_mtrl_consume + '' : ''
              "
              placement="top-start"
            >
              <el-input
                show-word-limit
                maxlength="9"
                v-model="scope.row.smpl_mtrl_consume"
                @change="totalPriceChange(scope.$index)"
                @input="scope.row.smpl_mtrl_consume.smpl_mtrl_consume = helper.keepTNum1(scope.row.smpl_mtrl_consume)"
                @blur="scope.row.smpl_mtrl_consume = helper.calcPrice(scope.row.smpl_mtrl_consume, 4, 10000)"
                placeholder="暂无用量"
              />
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="单价" show-overflow-tooltip>
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'smpl_mtrl_list.' + scope.$index + '.mtrl_prod_price'">
            <el-tooltip
              class="item"
              effect="light"
              :content="
                smplForm.smpl_mtrl_list[scope.$index].mtrl_prod_price ? smplForm.smpl_mtrl_list[scope.$index].mtrl_prod_price + '' : ''
              "
              placement="top"
            >
              <el-input
                :disabled="true"
                show-word-limit
                @change="totalPriceChange(scope.$index)"
                maxlength="6"
                v-model="smplForm.smpl_mtrl_list[scope.$index].mtrl_prod_price"
                placeholder="暂无单价"
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <!-- <el-table-column show-overflow-tooltip>
        <template slot="header">
          <span>剪料单位</span>
        </template>
        <template>
          <el-input size="mini" :disabled="true" value="厘米"></el-input>
        </template>
      </el-table-column> -->
      <!-- <el-table-column show-overflow-tooltip label="损耗比率">
        <template v-slot="scope">
          <el-form-item
            label-width="0"
            :prop="'smpl_mtrl_list.' + scope.$index + '.smpl_mtrl_loss'"
          >
            <el-tooltip
              class="item"
              effect="light"
              :content="
                smplForm.smpl_mtrl_list[scope.$index].smpl_mtrl_loss
                  ? smplForm.smpl_mtrl_list[scope.$index].smpl_mtrl_loss + ''
                  : ''
              "
              placement="top-start"
            >
              <el-input
              v-if="smplForm.smpl_mtrl_list[scope.$index].mtrl_type === 0"
                show-word-limit
                maxlength="9"
                v-model="
                  smplForm.smpl_mtrl_list[scope.$index].smpl_mtrl_loss
                "
                @input="
                  smplForm.smpl_mtrl_list[scope.$index].smpl_mtrl_loss =
                    helper.keepTNum1(
                      smplForm.smpl_mtrl_list[scope.$index].smpl_mtrl_loss
                    )
                "
                @blur="smplForm.smpl_mtrl_list[scope.$index].smpl_mtrl_loss=helper.retain1(smplForm.smpl_mtrl_list[scope.$index].smpl_mtrl_loss,2,1000000)"
                @change="lossChange(smplForm.smpl_mtrl_list[scope.$index].smpl_mtrl_loss,scope.$index)"
                placeholder="暂无损耗比率"
              ></el-input>
              <el-input
              v-if="smplForm.smpl_mtrl_list[scope.$index].mtrl_type !== 0"
                show-word-limit
                maxlength="9"
                disabled
                v-model="
                  valueType
                "
                placeholder="暂无损耗比率"
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column> -->
      <el-table-column show-overflow-tooltip label="金额">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'smpl_mtrl_list.' + scope.$index + '.totalChange'">
            <el-input
              disabled
              show-word-limit
              maxlength="9"
              v-model="smplForm.smpl_mtrl_list[scope.$index].totalChange"
              placeholder="暂无金额"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="提示:请输入物料长度和物料宽度" :visible.sync="dialogVisible" width="45%" :before-close="handelClose">
      <el-row>
        <el-form>
          <el-col :md="11">
            <el-form-item label="长：" label-width="60px">
              <el-input
                maxlength="7"
                show-word-limit
                v-model="value"
                @input="value = helper.keepTNum1(value)"
                @change="value = helper.retain1(value, 2, 10000)"
              >
                <template slot="append">厘米</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="2" class="vd_center">*</el-col>
          <el-col :md="11">
            <el-form-item label="宽：" label-width="60px">
              <el-input
                maxlength="7"
                show-word-limit
                v-model="value2"
                @input="value2 = helper.keepTNum1(value2)"
                @change="value2 = helper.retain1(value2, 2, 10000)"
              >
                <template slot="append">厘米</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="detClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'SmplEditBomChild',
  props: {
    smplForm: {
      type: Object,
      required: true
    },
    mtrlTypeGroupOpt: {
      type: Array,
      required: true
    },
    isShow: {
      type: Boolean
    }
  },
  data() {
    return {
      selectionsList: [],
      mtrlType: [
        { id: 0, label: '原面料' },
        { id: 1, label: '辅料' },
        { id: 2, label: '包材' }
      ],
      mtrlType1: '原面料',
      mtrlType2: '辅料',
      mtrlType3: '包材',
      dialogVisible: false,
      rowIndex: null,
      index: '',
      value: '', //新增剪料 第一个输入框
      value2: '', //新增剪料第二个输入框
      clearFlag: true,
      valueType: '暂无'
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {},
    //bom清单选择框
    handleSelectionChange(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
      this.$emit('handleSelectionChange', this.selectionsList);
    },
    handleClose(rowI, index) {
      this.clearFlag = false;
      let str = '';
      this.smplForm.smpl_mtrl_list[rowI].smplMtrlLenWidList.splice(index, 1);
      for (let i = 0; i < this.smplForm.smpl_mtrl_list[rowI].smplMtrlLenWidList.length; i++) {
        if (i === this.smplForm.smpl_mtrl_list[rowI].smplMtrlLenWidList.length - 1) {
          str = str + this.smplForm.smpl_mtrl_list[rowI].smplMtrlLenWidList[i].label;
        } else {
          str = str + this.smplForm.smpl_mtrl_list[rowI].smplMtrlLenWidList[i].label + ',';
        }
      }
      this.smplForm.smpl_mtrl_list[rowI].smpl_mtrl_len_wid = str;
      this.$nextTick(() => {
        this.clearFlag = true;
      });
    },
    addTag(rowIndex, index) {
      this.rowIndex = rowIndex;
      this.index = index;
      this.dialogVisible = true;
    },
    // 损耗比率改变
    // lossChange(val,val1){
    //   if(!val){
    //     this.smplForm.smpl_mtrl_list[val1].smpl_mtrl_loss = '1.03'
    //   }
    //   this.totalPriceChange(val1)
    // },
    // 计算总金额
    totalPriceChange(scope) {
      this.$emit('priceChange', scope);
    },
    //关闭 dialog
    handelClose() {
      this.value = '';
      this.value2 = '';
      this.dialogVisible = false;
    },
    detClick() {
      if (this.value == '' || this.value2 == '') {
        return this.$message.warning('长和宽不可以为空');
      }
      if (this.value == '0.00' || this.value2 == '0.00') {
        return this.$message.warning('长和宽不可以为0');
      }
      let temp = false;
      let temp1 = false;
      let temp2 = false;
      let temp3 = false;
      if (
        this.value > Number(this.smplForm.smpl_mtrl_list[this.rowIndex].mtrl_width) * 100 &&
        this.value2 > Number(this.smplForm.smpl_mtrl_list[this.rowIndex].mtrl_width) * 100
      ) {
        temp = true;
      }
      if (
        this.value > Number(this.smplForm.smpl_mtrl_list[this.rowIndex].mstk_num) * 100 &&
        this.value2 > Number(this.smplForm.smpl_mtrl_list[this.rowIndex].mstk_num) * 100
      ) {
        temp1 = true;
      }
      if (
        this.value > Number(this.smplForm.smpl_mtrl_list[this.rowIndex].mtrl_width) * 100 &&
        this.value > Number(this.smplForm.smpl_mtrl_list[this.rowIndex].mstk_num) * 100
      ) {
        temp2 = true;
      }
      if (
        this.value2 > Number(this.smplForm.smpl_mtrl_list[this.rowIndex].mtrl_width) * 100 &&
        this.value2 > Number(this.smplForm.smpl_mtrl_list[this.rowIndex].mstk_num) * 100
      ) {
        temp3 = true;
      }
      if (temp || temp1 || temp2 || temp3) {
        return this.$message.warning('剪料长和宽不能同时超过物料门幅和库存数');
      }
      if (!this.smplForm.smpl_mtrl_list[this.rowIndex].smpl_mtrl_len_wid) {
        this.$set(this.smplForm.smpl_mtrl_list[this.rowIndex], 'smpl_mtrl_len_wid', '');
      } else {
        this.smplForm.smpl_mtrl_list[this.rowIndex].smpl_mtrl_len_wid += ',';
      }
      this.clearFlag = false;
      this.$forceUpdate();
      let newValue = this.value + '*' + this.value2;
      let tempList = {};
      tempList.key = this.smplForm.smpl_mtrl_list[this.rowIndex].smplMtrlLenWidList.length;
      tempList.label = newValue;
      tempList.closableFlag = true;
      this.smplForm.smpl_mtrl_list[this.rowIndex].smplMtrlLenWidList.push(tempList);
      let str = '';
      for (let i = 0; i < this.smplForm.smpl_mtrl_list[this.rowIndex].smplMtrlLenWidList.length; i++) {
        if (i === this.smplForm.smpl_mtrl_list[this.rowIndex].smplMtrlLenWidList.length - 1) {
          str = str + this.smplForm.smpl_mtrl_list[this.rowIndex].smplMtrlLenWidList[i].label;
        } else {
          str = str + this.smplForm.smpl_mtrl_list[this.rowIndex].smplMtrlLenWidList[i].label + ',';
        }
      }
      this.smplForm.smpl_mtrl_list[this.rowIndex].smpl_mtrl_len_wid = str;
      this.value = '';
      this.value2 = '';
      this.dialogVisible = false;
      this.$nextTick(() => {
        this.clearFlag = true;
      });
    }
  }
};
</script>

<style scoped>
.circle {
  background-color: #c4bcbc;
  border-radius: 50%;
  padding: 2px;
  border: 0;
  color: #fff;
}
.elTagItem {
  padding: 0 2px;
  margin-right: 2px;
  height: 24px;
  line-height: 24px;
}
.vd_center {
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 24px;
}
</style>
